"use client";
import { twMerge } from "tailwind-merge";
import { LongButton } from "../../stories/Button.stories";
import { TextInput } from "../Inputs/TextInput";
import Button from "./Button";

interface NewsletterProps {
    title?: string;
    description?: string;
    hasInput?: boolean;
    hasCheckbox?: boolean;
    hasDescription?: boolean;
    inputText?: string;
    buttonText?: string;
    checkBoxText?: string;
    subText?: string;
    backgroundColor?: string;
    hasSubText?: boolean;
    placeholderText?: string;
    differentText?: JSX.Element;
    hasTextArea?: boolean;
    hasForm?: boolean;
    onSubmitForm?: () => void;
    inputId?: string;
    onClickButton?: () => void;
    paddings?: string;
    buttonPaddings?: string;
    newsletterForm?: boolean;
    form?: JSX.Element;
    buttonAs?: "button" | "a";
    href?: string;
}
export default function Newsletter({
    title = "Du hast gerade eine Idee für ein Thema von Morgen?",
    description = "Meine Arbeit hat das Ziel, dir nachhaltig in deine natürlich Kraft und Stärke zu helfen. Veränderung ist nur durch Stärke möglich und dazu braucht es funktionierende Energiefelder.",
    hasInput = true,
    hasCheckbox = true,
    hasDescription = false,
    buttonText = "Abonnieren",
    inputText = "Deine E-Mail-Adresse",
    checkBoxText = "I have read and accept the privacy policy.",
    subText = "* Unsubscribing from the newsletter is possible at any time.",
    backgroundColor = "bg-yellow",
    hasSubText = true,
    placeholderText = "Deine E-Mail-Adresse",
    differentText,
    hasTextArea = false,
    hasForm = false,
    onClickButton = () => {},
    onSubmitForm = () => {},
    inputId = "email",
    paddings = " px-3 py-25 lg:px-96 lg:py-25 md:px-24",
    buttonPaddings = "",
    newsletterForm = false,
    form = <></>,
    buttonAs = "button",
    href = "",
}: NewsletterProps) {
    return (
        <div>
            <div
                className={twMerge(
                    "w-full flex-col justify-start items-center gap-6 inline-flex",
                    backgroundColor,
                    paddings,
                )}
            >
                <div className="self-stretch text-center text-black text-xl3 lg:text-5xl font-bold  leading-10 lg:leading-14">
                    {title}
                </div>
                <div className=" w-full flex-col justify-start items-center gap-4 flex">
                    {newsletterForm && (
                        <form
                            action="https://rico-brunner.us1.list-manage.com/subscribe/post?u=d1c20fb888e0881970e01474a&amp;id=fba1037da3&amp;f_id=00c51ee0f0"
                            method="post"
                            id="mc-embedded-subscribe-form"
                            name="mc-embedded-subscribe-form"
                            target="_self"
                        >
                            <div className="space-y-4 flex flex-col justify-center ">
                                <div className=" flex-col justify-start items-start gap-1 flex">
                                    <div className=" justify-start items-start gap-1 inline-flex" />
                                    <TextInput
                                        placeholder={placeholderText}
                                        label={inputText}
                                        name={"EMAIL"}
                                        id={"mce-EMAIL"}
                                        type="email"
                                        width="lg:w-120 md:w-120 w-83"
                                        backgroundColor={backgroundColor}
                                    />
                                </div>
                                {hasTextArea && (
                                    <>
                                        <TextInput
                                            type="textarea"
                                            width="lg:w-120 md:w-120 w-83"
                                            name="topic"
                                            id="topic"
                                            label="Thema senden"
                                            backgroundColor={backgroundColor}
                                            placeholder="Hier eingeben"
                                        />
                                    </>
                                )}
                                <div className="flex justify-center">
                                    <Button
                                        backgroundColor={
                                            LongButton.args?.backgroundColor
                                        }
                                        textColor={LongButton.args?.textColor}
                                        text={buttonText}
                                        textSize={LongButton.args?.textSize}
                                        paddings={buttonPaddings}
                                        rounded={LongButton.args?.rounded}
                                        size={LongButton.args?.size}
                                        border={LongButton.args?.border}
                                        buttonType="submit"
                                    />
                                </div>
                            </div>
                        </form>
                    )}
                    {description !== "" ? (
                        <div className="w-full text-center text-black text-lg lg:text-xl1 font-normal font-['Baton Turbo Web'] leading-7 lg:leading-8.5">
                            {description}
                        </div>
                    ) : (
                        <div>{differentText}</div>
                    )}

                    {hasForm && form}
                    {!newsletterForm && !hasForm && (
                        <Button
                            backgroundColor={LongButton.args?.backgroundColor}
                            textColor={LongButton.args?.textColor}
                            text={buttonText}
                            textSize={LongButton.args?.textSize}
                            paddings={buttonPaddings}
                            rounded={LongButton.args?.rounded}
                            size={LongButton.args?.size}
                            border={LongButton.args?.border}
                            onClick={onClickButton}
                            as={buttonAs}
                            href={href}
                        />
                    )}

                    <div className=" flex-col md:justify-center justify-start md:items-center items-start gap-1 flex">
                        {hasCheckbox && (
                            <div className=" justify-start md:justify-center md:items-center md:px-28 items-center gap-1 inline-flex">
                                <input
                                    type="checkbox"
                                    className=" w-5 h-5 left-0.5 top-0.5  bg-black rounded-sm"
                                />
                                <div className="w-full text-black text-xl1 font-normal  leading-tight lg:leading-8.5">
                                    {checkBoxText}
                                </div>
                            </div>
                        )}

                        {hasSubText && (
                            <div className="lg:-pl-2 md:justify-center md:items-center justify-start items-start  inline-flex">
                                <div className="grow shrink basis-0 text-black text-base font-normal  leading-tight">
                                    {subText}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
