"use client";
import { Modal } from "@mantine/core";
import { useEffect, useState } from "react";

interface InfoModalProps {
    children: React.ReactNode;
    opened: boolean;
    onClose: () => void;
    size?: "xs" | "sm" | "md" | "lg" | "xl" | "full";
    paddings?: string;
    fullScreen?: boolean;
}

export default function InfoModal({
    children,
    opened,
    onClose,
    size,
    paddings = "p-10",
    fullScreen = true,
}: InfoModalProps) {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.innerWidth < 768) {
            setIsMobile(true);
        }
    }, []);

    return (
        <>
            <Modal
                fullScreen={isMobile && fullScreen}
                onClose={onClose}
                opened={opened}
                size={size}
                centered
                className="bg-opacity-20"
            >
                <div className={`${paddings}`}>{children}</div>
            </Modal>
        </>
    );
}
