import { useUncontrolled } from "@mantine/hooks";
import Image from "next/image";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
interface TextInputProps {
    id: string;
    label: string;
    name: string;
    type: "text" | "textarea" | "email";
    placeholder?: string;
    error?: string;
    value?: string;
    onChange?: (value: string) => void;
    required?: boolean;
    backgroundColor?: string;
    width?: string;
    height?: string;
    placeholderColor?: string;
    hasIcon?: boolean;
    iconSrc?: string;
    border?: string;
    ring?: string;
    placeholderPadding?: string;
    textColor?: string;
}

export const TextInput: React.FC<TextInputProps> = ({
    id,
    label,
    name,
    type,
    placeholder,
    error,
    value,
    onChange,
    required = false,
    backgroundColor,
    width = "w-full",
    height,
    placeholderColor = "placeholder-darkGray",
    hasIcon,
    iconSrc,
    border = "border-2 border-black",
    ring = "ring-1 ring-inset ring-white/10 focus:ring-2",
    placeholderPadding = "pl-2",
    textColor = "text-darkGray",
    ...props
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const [innerValue, setInnerValue] = useUncontrolled({
        value,
        defaultValue: "",
        finalValue: "",
        onChange,
    });

    return (
        <div>
            <label
                htmlFor={name}
                className="grow shrink basis-0 text-neutral-800 lg:text-lg text-base font-bold  leading-tight"
            >
                {label} {required && <span className="text-[#FB6D68]">*</span>}
            </label>
            <div className="mt-1 relative">
                {type === "textarea" ? (
                    <textarea
                        id={id}
                        name={name}
                        placeholder={placeholder}
                        className={twMerge(
                            "block rounded-sm border-2 border-black  py-1.5 text-darkGray shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 sm:text-sm sm:leading-6 pl-2 placeholder-darkGray",
                            width,
                            backgroundColor,
                        )}
                        value={innerValue}
                        onChange={(event) => setInnerValue(event.currentTarget.value)}
                        {...props}
                    />
                ) : (
                    <div className="flex">
                        {hasIcon && (
                            <div className="absolute inset-y-0 left-0 pr-3 flex items-center cursor-pointer">
                                <Image
                                    height={20}
                                    width={20}
                                    src={iconSrc || ""}
                                    alt=""
                                />
                            </div>
                        )}
                        <input
                            id={id}
                            name={name}
                            type={type}
                            placeholder={placeholder}
                            className={twMerge(
                                "block rounded-sm py-1.5  shadow-sm  sm:text-sm sm:leading-6 ",
                                width,
                                backgroundColor,
                                error && "border-[#FB6D68]",
                                placeholderColor,
                                border,
                                ring,
                                placeholderPadding,
                                textColor,
                            )}
                            value={innerValue}
                            onChange={(event) =>
                                setInnerValue(event.currentTarget.value)
                            }
                            {...props}
                        />
                    </div>
                )}

                {error && <div className="text-[#FB6D68] mt-2 text-xs">{error}</div>}
            </div>
        </div>
    );
};
