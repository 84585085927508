import type { Meta, StoryObj } from "@storybook/react";
import Button from "../components/Cards/Button";

const meta = {
    title: "Example/Button",
    component: Button,
    argTypes: {
        backgroundColor: { control: "color" },
    },
} as Meta;
export default meta;
type Story = StoryObj<typeof meta>;

export const ButtonDark: Story = {};

export const ButtonTransparent: Story = {
    args: {
        size: "w-fit h-15",
        border: "border-2 border-black",
        rounded: "rounded-lg",
        backgroundColor: "bg-transparent",
        textColor: "text-black",
        text: "MEHR DAZU",
    },
};
export const ButtonTransparentWithICon: Story = {
    args: {
        size: "w-fit h-15",
        border: "border-2 border-black",
        rounded: "rounded-lg",
        backgroundColor: "bg-transparent",
        textColor: "text-black",
        text: "MEHR DAZU",
        hasIcon: true,
        paddings: "px-6 py-4",
    },
};
export const ButtonTransparentRounded: Story = {
    args: {
        size: "w-fit h-14.5",
        border: "border-2 border-black",
        rounded: "rounded-7xl",
        backgroundColor: "",
        textColor: "text-black font-semibold",
        text: " Symptome",
    },
};
export const SigninButton: Story = {
    args: {
        size: "w-74 h-12",
        rounded: "rounded-lg",
        backgroundColor: "bg-cyan-800",
        textColor: "text-white ",
        textSize: "text-base font-normal",
        text: "Sign in",
    },
};
export const SignUpButton: Story = {
    args: {
        size: "w-74 h-12",
        rounded: "rounded-lg",
        backgroundColor: "bg-neutral-100 bg-opacity-70",
        border: "border border-cyan-800",
        textColor: "text-cyan-800",
        textSize: "text-base font-normal",
        text: "Create account",
    },
};
export const ButtonRoundedColor: Story = {
    args: {
        size: "w-34 h-14.5",
        rounded: "rounded-4xl ",
        border: "",
        backgroundColor: "bg-blue",
        textColor: "text-white",
        textSize: "text-2xl font-bold ",
        text: "kostenlos",
        paddings: "px-6 py-3",
        hasIcon: false,
    },
};

export const LongButton: Story = {
    args: {
        // size: "lg:w-120 lg:h-15 w-83 h-15",
        size: "min-h-15 w-fit",
        rounded: "rounded-lg",
        backgroundColor: "bg-black",
        textSize: "uppercase text-xl font-bold",
        text: "JETZT ANFRAGEN",
        paddings: " py-4 px-6",
        textColor: "text-white",
    },
};
export const MdButton: Story = {
    args: {
        size: "min-w-83 h-16",
        rounded: "rounded-lg",
        backgroundColor: "bg-black",
        textColor: "text-white",
        textSize: "text-xl font-bold uppercase",
        text: "HEUTE NOCH BEGINNEN",
        paddings: "px-6 py-4",
    },
};
export const WatchVideoButton: Story = {
    args: {
        size: "w-42 h-10.5",
        rounded: "rounded-7xl ",
        border: "border border-black",
        backgroundColor: "bg-transparent",
        textColor: "text-black",
        textSize: "text-xl1 font-normal ",
        text: "Watch video",
        hasIcon: true,
        iconPlacement: "left",
        iconSrc: "/Icons/PlayIconBlack.svg",
        paddings: "py-1 ",
    },
};
export const MDButtonIcon: Story = {
    args: {
        size: "lg:w-[348px] w-[327px] h-[60px]",
        backgroundColor: "bg-black",
        hasIcon: true,
        iconPlacement: "right",
        iconSrc: "/Icons/Header/arrowRWhite.svg",
        text: "hier geht’s zum ticket",
        paddings: "py-4 px-0",
        textSize: "text-base lg:text-xl uppercase font-bold",
    },
};
export const GetFreeVideosButton: Story = {
    args: {
        size: "w-[220px] h-[42px]",
        rounded: "rounded-lg",
        paddings: "px-2 py-2",
        hasIcon: true,
        iconPlacement: "left",
        iconSrc: "/Icons/likeCyan.svg",
        text: "Get 8 free videos",
        textColor: "text-cyan",
        textSize: "text-xl1  font-normal",
        backgroundColor: "bg-cyan bg-opacity-30",
    },
};
